import request from 'superagent'
import SignaturePad from 'signature_pad'
import { addToast } from 'src/toasts'
import { lockButton, unlockButton } from 'src/lockButton'
import { handleErrors } from 'src/errors'

let prevWidth
let modalCanvas
let modalSignaturePad

export default () => {
  $('#quality-controls').on('change', 'form input, form select, form textarea, form checkbox, form radio', function(event) {
    dirty = true
  })

  $('body.quality_controls.laatu').on('click', '#submitForm', function(event) {
    if ($('#quality-controls:visible').length > 0) {
      lockButton('#submitForm')
      $('form button[type=submit]').click()
    }
  })
  
  $('#quality_control_id').on('change', function() {
    const qualityControlId = $(this).val()

    if (qualityControlId) {
      lockButton('#quality_control_id')
      $.getScript('/quality_controls/' + qualityControlId + '/edit')
    }
  })

  $('#quality-controls').on('click', '.quality_control_add_fields', function(event) {
    var regexp, time
    time = new Date().getTime()
    regexp = new RegExp($(this).data('id'), 'g')
    $('#collapseContactPeople .accordion-button').not(".collapsed").click()
    $('#collapseContactPeople .accordion').append($(this).data('fields').replace(regexp, time))
    $('#collapseContactPeople .accordion-button.collapsed').last().click()
    $('#accordionContactPeople .accordion-header').first().find('.accordion-button.collapsed').click()

    $(this).blur()

    return event.preventDefault()
  })

  $('#quality-controls').on('click', '.quality-control-remove-record', function(event) {
    $(this).closest('.contact_person').find('input[id*="_destroy"]').val('1')
    $(this).closest('.contact_person').addClass('d-none')
    $(this).closest('.contact_person').find('.accordion-collapse').remove()

    let firstName = $('.contact_person:visible:first input[name*="first_name"]:first').val()
    let lastName = $('.contact_person:visible:first input[name*="last_name"]:first').val()

    $('#quality_control_contact_name').html((firstName || '') + ' ' + (lastName || ''))

    return event.preventDefault()
  })

  $('#quality-controls').on('click', '.laatu-rating, .quality_control_select_contact_person.laatu', function(event) {
    $(this).closest('h2').find('button.collapsed').click()
  })

  $('#quality-controls').on('change', '.laatu-rating', function(event) {
    let selected = $('#quality-controls #accordionAreas select :selected').map(function(i, el) {
      return parseFloat($(el).val());
    }).get()
    let arr = selected.filter(function(val) {
      return val;
    })
    const average = arr.reduce((a, b) => a + b, 0) / arr.length;

    $('#quality-controls #accordionAreas .avg').html(average.toLocaleString('fi-FI', { minimumFractionDigits: 2, maximumFractionDigits: 2 }))
  })

  $('#quality-controls').on('change', '.quality_control_select_contact_person', function(event) {
    const id = $(this).val()
    $(this).closest('.contact_person').find('input[name*="contact_person_id"]').val(id)
    let firstSelectId = $('.contact_person:visible:first select:first').attr('id')

    if (id) {
      lockButton(event.target)

      const customerId = $("#quality_control_customer_id").val()
      request.get(/customers/ + customerId + /contact_people/ + id)
        .set('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
        .accept('json')
        .then(res => {
          $(this).closest('.contact_person').find('input[name*="first_name"]').val(res.body.first_name)
          $(this).closest('.contact_person').find('input[name*="first_name"]').prop("disabled", res.body.deleted)
          $(this).closest('.contact_person').find('input[name*="last_name"]').val(res.body.last_name)
          $(this).closest('.contact_person').find('input[name*="last_name"]').prop("disabled", res.body.deleted)
          $(this).closest('.contact_person').find('input[name*="phone"]').val(res.body.phone)
          $(this).closest('.contact_person').find('input[name*="phone"]').prop("disabled", res.body.deleted)
          $(this).closest('.contact_person').find('input[name*="email"]').val(res.body.email)
          $(this).closest('.contact_person').find('input[name*="email"]').prop("disabled", res.body.deleted)
          $(this).closest('.contact_person').find('input[name*="title"]').val(res.body.title)
          $(this).closest('.contact_person').find('input[name*="title"]').prop("disabled", res.body.deleted)

          if (firstSelectId === $(this).attr('id')) {
            $('#quality_control_contact_name').html(res.body.first_name + ' ' + res.body.last_name)
          }

          unlockButton(event.target)
        })
        .catch(err => {
          // err.message, err.response
          // TODO käännös
          if (err.response !== undefined && err.response.status === 401 && err.response.body && err.response.body['error'] === 'Istuntosi on vanhentunut, ole hyvä ja kirjaudu sisään jatkaaksesi.') {
            $.getScript('/redirect_to_root')
          } else {
            addToast(err.response !== undefined && err.response.body !== undefined && err.response.body !== null ? err.response.body : null, 'error', 'laatu')
            unlockButton(event.target)
          }
        })
    } else {
      $(this).closest('.contact_person').find('input[name*="first_name"]').val('')
      $(this).closest('.contact_person').find('input[name*="first_name"]').prop("disabled", false)
      $(this).closest('.contact_person').find('input[name*="last_name"]').val('')
      $(this).closest('.contact_person').find('input[name*="last_name"]').prop("disabled", false)
      $(this).closest('.contact_person').find('input[name*="phone"]').val('')
      $(this).closest('.contact_person').find('input[name*="phone"]').prop("disabled", false)
      $(this).closest('.contact_person').find('input[name*="email"]').val('')
      $(this).closest('.contact_person').find('input[name*="email"]').prop("disabled", false)
      $(this).closest('.contact_person').find('input[name*="title"]').val('')
      $(this).closest('.contact_person').find('input[name*="title"]').prop("disabled", false)

      if (firstSelectId === $(this).attr('id')) {
        $('#quality_control_contact_name').html('')
      }
    }
  })

  $('#quality-controls').on('change', 'input[type="file"]', function(event) {
    // varoita jos samanniminen on jo olemassa
    let filenames = []
    $('#quality-controls div.file').each(function() {
      filenames.push($(this).html())
    })
    $('#quality-controls a.file-download').each(function() {
      filenames.push($(this).html())
    })
    if (filenames.includes($(this)[0].files[0].name)) {
      addToast('Samanniminen liite on jo olemassa', 'alert', 'laatu')
    }

    const idNr = $(this)[0].id.split('_').slice(-1)
    const newIdNr = (parseInt(idNr) + 1).toString()
    const newId = $(this)[0].id.replace('add_files_' + idNr, 'add_files_' + newIdNr)
    $('<input id="' + newId + '" name="' + $(this)[0].name + '" type="file" class="d-none" />').insertAfter($(this)[0])
    $(this).siblings('label:first').attr('for', newId)
    $('<div class="row">' +
      '<div class="col-10 file">' + $(this)[0].files[0].name + '</div>' +
        '<div class="col-2 text-end">' +
          '<a class="remove-file-laatu btn btn-outline-danger text-danger mb-1" data-id="' + $(this)[0].id + '" data-new="true" href="#"><i class="bi bi-trash"></i></a>' +
        '</div>' +
      '</div>').insertAfter($(this)[0])
  })

  $('#quality-controls').on('click', '.remove-file-laatu', function(event) {
    if ($(this).attr('data-new') == 'true') {
      $('#' + $(this).attr('data-id')).remove()
    } else {
      const input = $(this).closest('.col-12').find('input[id*="remove_files"]')
      let val = input.val()
      if (val.length) {
        val += ','
      }
      val += $(this).attr('data-id')
      input.val(val)
    }

    $(this).closest('.row').remove()

    return event.preventDefault()
  })

  $('.quality_controls.laatu').on('click', '.toast', function(event) {
    $(this).hide()
  })

  $('#quality-controls').on('click', '#sign_user, #sign_contact', function(event) {
    if ($('#accordionContactPeople').length && $('.accordion-item.contact_person:not(.d-none)').length === 0) {
      // TODO käännös
      addToast('Yhteyshenkilö puuttuu', 'alert', 'laatu')
      $('.quality_control_add_fields.add_contact_person_laatu').click()
    } else {
      if ($(this).attr("id") == 'sign_user') {
        $('#quality_control_sign').val('user')
      } else {
        $('#quality_control_sign').val('contact')
      }

      $('#submitForm').click()
    }
  })

  $('#mainModal').on('click', '#signature-pad-modal-clear', function(event) {
    modalSignaturePad.clear()
  })

  $('#mainModal').on('click', '#signature-pad-modal-cancel', function(event) {
    mainModal.hide()
  })

  $('#mainModal').on('click', '#signature-pad-modal-save', function(event) {
    if (typeof modalSignaturePad != 'undefined' && !modalSignaturePad.isEmpty()) {
      let cachedImage = modalSignaturePad.toDataURL("image/png")
      if ($(this).attr("data-modal-user") == 'user') {
        $("#quality_control_signature_data_user").val(cachedImage)
      } else {
        $("#quality_control_signature_data_contact").val(cachedImage)
      }
      mainModal.hide()
      $('#submitForm').click()
    } else {
      // TODO käännös
      addToast('Allekirjoitus puuttuu', 'alert', 'laatu')
    }
  })

  $('#quality-controls').on('click', 'button[type=submit]', function(event) {
    let accordionStates = []
    $('.accordion-button').each(function(index) {
      let s = $(this).data('bs-target').replace('#collapse', '') + '='
      if ($(this).hasClass('collapsed')) {
        s += 't'
      } else {
        s += 'f'
      }
      accordionStates.push(s)
    })

    $('#quality_control_accordion_states').val(accordionStates.join(','))
  })
}

function resizeSignatureAndCanvas() {
  // if there are no lines drawn, don't need to scale them
  if ($('#mainModal.show').length) {
    if (modalSignaturePad.isEmpty()) {
      // Set initial size
      resizeCanvas();
    } else {
      // Get the current canvas contents
      let lines = modalSignaturePad.toDataURL("image/png");
      
      // Match canvas to window size/device change
      resizeCanvas();

      // Calculate new size
      let currentWidth = $('#signature-pad-wrapper canvas').width();
      let scale = currentWidth / prevWidth;
      prevWidth = currentWidth; // Prepare for next time

      // Load the adjusted canvas contents
      modalSignaturePad.fromDataURL(lines)
    }
  }
};

function resizeCanvas() {
  /** Have to resize manually to keep the canvas the width of the
  *   window without distorting the location of the "pen". */
  
  // I'm not completely sure of everything in here
  const canvas = $('#signature-pad-wrapper canvas')[0];

  const bodyWidth = $('#mainModal .modal-body').width()
  const bodyHeight = $('#mainModal .modal-body').height()
  let w = bodyWidth
  let h = (9 * bodyWidth) / 16
  if (h > bodyHeight) {
    w = (16 * bodyHeight) / 9
    h = bodyHeight
  }
  $('#mainModal #signature-pad-wrapper').innerWidth(w)
  $('#mainModal #signature-pad-wrapper').innerHeight(h)

  // Not sure why we need both styles and props
  canvas.style.width = w + 'px';
  canvas.style.height = h + 'px';
  
  // When zoomed out to less than 100%, for some very strange reason,
  // some browsers report devicePixelRatio as less than 1
  // and only part of the canvas is cleared then.
  let ratio = Math.max(window.devicePixelRatio || 1, 1);
  // This part causes the canvas to be cleared
  canvas.width = canvas.offsetWidth * ratio;
  canvas.height = canvas.offsetHeight * ratio;
  canvas.getContext("2d").scale(ratio, ratio);
  modalSignaturePad.clear()
};

window.openSignatureModal = function() {
  let userName = ''
  const modalUser = $('#quality_control_sign').val()
  
  if (modalUser == 'user') {
    userName = $('#quality_control_user_name').text()
  } else if (modalUser == 'contact') {
    userName = $('#quality_control_contact_name').text()
  }
  userName = userName.trim()

  $('#quality_control_sign').val('')

  // TODO käännökset
  $("#mainModal").html('<div class="modal-dialog modal-fullscreen"><div class="modal-content"><div class="modal-header text-center"><h4 class="modal-title w-100 font-weight-bold">' + userName + '</h4><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div><div class="modal-body d-flex justify-content-center"><div id="signature-pad-wrapper"><canvas></canvas></div></div><div class="modal-footer"><button id="signature-pad-modal-clear" type="button" class="btn btn-danger"><i class="bi bi-trash"></i> Tyhjennä</button><button id="signature-pad-modal-cancel" type="button" class="btn btn-secondary"><i class="bi bi-close"></i> Peruuta</button><button id="signature-pad-modal-save" type="button" class="btn btn-success" data-modal-user="' + modalUser + '"><i class="bi bi-check"></i> Tallenna</button></div></div></div>')
  mainModal.show()

  modalCanvas = document.querySelector("#mainModal canvas")
  modalSignaturePad = new SignaturePad(modalCanvas)
  prevWidth = $('#signature-pad-wrapper canvas').width();

  setTimeout(resizeSignatureAndCanvas, 0);
  window.addEventListener("resize", () => setTimeout(resizeSignatureAndCanvas, 0));
  window.addEventListener("orientationchange", () => setTimeout(resizeSignatureAndCanvas, 0));
}
