import { customerFunctions } from 'packs/customers/functions'
import request from 'superagent' // https://visionmedia.github.io/superagent/
import { addToast } from 'src/toasts'

export default () => {
  customerFunctions()

  let selectedIds = null

  // datatable init
  let callListDatatable = $('#call-list-datatable').DataTable({
    "language": {
      url: "/datatable_i18n"
    },
    "pageLength": 25,
    "processing": true,
    "responsive": true,
    "serverSide": true,
    "ajax": {
      "url": $('#call-list-datatable').data('source')
    },
    "buttons": [
      {
        attr: {
          id: 'import_events'
        },
        className: 'btn btn-primary me-4',
        text: function (dt) {
          return dt.i18n("buttons.import_events")
        },
        action: function (e, dt, node, config) {
          request.get('/search_users?role=BUUKKAAJA')
            .set('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
            .accept('json')
            .then(res => {
              let input = '<input type="file" id="import_file">'

              let select = '<select id="import-events-user-select" class="form-select"><option value="0">Kaikki</option>'
              const a = res.body
              for(let i in a) {
                select += '<option value="' + a[i].id + '">' + a[i].fullName + '</option>'
              }
              select += '</select>'

              // TODO käännös
              $("#mainModal").html('<div class="modal-dialog"><div class="modal-content"><div class="modal-header text-center"><h4 class="modal-title w-100 font-weight-bold">Siirrä buukkaukset?</h4><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div><div class="modal-body">' + input + '<br>Tuo soittolista buukkaajalle ' + select + '</div><div class="modal-footer"><button id="confirmImportEvents" type="button" class="btn btn-success"><i class="bi bi-check-lg"></i> Tuo</button></div></div></div>')
              mainModal.show()
            })
            .catch(err => {
              // err.message, err.response
              const message = err.response !== undefined && err.response.body !== undefined && err.response.body !== null ? err.response.body : null
              if (err.response.status === 401) {
                if (message !== null) {
                  addToast(message, 'error')
                  setTimeout(function() {
                    $.getScript('/redirect_to_root')
                  }, 3000)
                } else {
                  $.getScript('/redirect_to_root')
                }
              } else {
                addToast(message, 'error')
              }
            })
        }
      },
      {
        attr: {
          id: 'transfer_events'
        },
        className: 'btn btn-primary me-4 d-none',
        text: function (dt) {
          return dt.i18n("buttons.transfer_events")
        },
        action: function (e, dt, node, config) {
          request.get('/search_users?role=BUUKKAAJA')
            .set('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
            .accept('json')
            .then(res => {
              selectedIds = Array.from(callListDatatable.rows('.selected').ids())
              let rows = selectedIds.length

              let select = '<select id="transfer-events-user-select" class="form-select"><option value="0">Kaikki</option>'
              const a = res.body
              for(let i in a) {
                select += '<option value="' + a[i].id + '">' + a[i].fullName + '</option>'
              }
              select += '</select>'

              // TODO käännös
              $("#mainModal").html('<div class="modal-dialog"><div class="modal-content"><div class="modal-header text-center"><h4 class="modal-title w-100 font-weight-bold">Siirrä buukkaukset?</h4><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div><div class="modal-body">Siirrä ' + rows + (rows > 1 ? ' tapahtumaa' : ' tapahtuma') + ' buukkaajalle ' + select + '</div><div class="modal-footer"><button id="confirmTransferEvents" type="button" class="btn btn-success"><i class="bi bi-check-lg"></i> Siirrä</button></div></div></div>')
              mainModal.show()
            })
            .catch(err => {
              // err.message, err.response
              const message = err.response !== undefined && err.response.body !== undefined && err.response.body !== null ? err.response.body : null
              if (err.response.status === 401) {
                if (message !== null) {
                  addToast(message, 'error')
                  setTimeout(function() {
                    $.getScript('/redirect_to_root')
                  }, 3000)
                } else {
                  $.getScript('/redirect_to_root')
                }
              } else {
                addToast(message, 'error')
              }
            })
        }
      },
      {
        attr: {
          id: 'delete_events'
        },
        className: 'btn btn-danger d-none',
        text: function (dt) {
          return dt.i18n("buttons.delete_events")
        },
        action: function (e, dt, node, config) {
          selectedIds = Array.from(callListDatatable.rows('.selected').ids())

          // TODO käännös
          $("#mainModal").html('<div class="modal-dialog"><div class="modal-content"><div class="modal-header text-center"><h4 class="modal-title w-100 font-weight-bold">Oletko varma?</h4><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div><div class="modal-body">Tätä toimintoa ei voi peruuttaa.</div><div class="modal-footer"><button id="confirmDeleteCallList" type="button" class="btn btn-danger"><i class="bi bi-trash"></i> Poista</button></div></div></div>')
            mainModal.show()
        }
      }
    ],
    "columns": [
      {"data": "user"},
      {"data": "company_name"},
      {"data": "location_name"},
      {"data": "business_id"},
      {"data": "address"},
      {"data": "customer_phone"},
      {"data": "contact_name"},
      {"data": "contact_phone"},
      {"data": "event_date"}
    ],
    "rowId": 'id',
    "order": [[8, 'asc']],
    initComplete(settings, json) {
      $(".dataTables_length").addClass('me-4')
      callListDatatable.buttons().container().appendTo($('.col-sm-12:eq(0)', callListDatatable.table().container() ) )
      $("#import_events").removeClass('btn-secondary')
      $("#transfer_events").removeClass('btn-secondary')
      $("#delete_events").removeClass('btn-secondary')
      $('.dt-buttons').addClass('align-top')

      // custom search bar
      $("#call-list-datatable_filter").append('<div class="input-group floating form-group"><div class="input-group-prepend"><span class="input-group-text"><i class="bi bi-search"></i></span></div><div class="form-floating"></div></div>')
      $("#call-list-datatable_filter input").removeClass('form-control-sm')
      $("#call-list-datatable_filter input").detach().appendTo('#call-list-datatable_filter .input-group.form-group .form-floating')
      $("#call-list-datatable_filter label").remove()
      $("#call-list-datatable_filter input").addClass('w-100 ms-0')
      $('<label>Hae</label>').insertAfter($("#call-list-datatable_filter input"))

      // user filter
      $('#call-list-datatable_length').addClass('align-top')
      $('#call-list-user-select-div').insertAfter($('#call-list-datatable_length'))
      $('#call-list-user-select-div').removeClass('d-none')
    }
  })

  $('#call-list-datatable tbody').on('click', 'tr', function () {
    $(this).toggleClass('selected')
    if (callListDatatable.rows('.selected').data().length > 0) {
      $("#transfer_events").removeClass('d-none')
      $("#delete_events").removeClass('d-none')      
    } else {
      $("#transfer_events").addClass('d-none')
      $("#delete_events").addClass('d-none')
    }
  })

  callListDatatable.on('draw', function () {
    $("#transfer_events").addClass('d-none')
    $("#delete_events").addClass('d-none')
  })

  $('#content').on('change', '#call-list-user-select', function() {
    callListDatatable.column(0).search($(this).val()).draw()
  })

  $('#mainModal').on('click', '#confirmDeleteCallList', function(event) {
    $(this).blur()
    request.delete('/delete_events')
      .set('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
      .accept('json')
      .send({
        ids: selectedIds
      })
      .then(res => {
        addToast(res.body.success, 'notice')
        callListDatatable.ajax.reload()
        mainModal.hide()
      })
      .catch(err => {
        // err.message, err.response
        const message = err.response !== undefined && err.response.body !== undefined && err.response.body !== null ? err.response.body : null
        if (err.response.status === 401) {
          if (message !== null) {
            addToast(message, 'error')
            setTimeout(function() {
              $.getScript('/redirect_to_root')
            }, 3000)
          } else {
            $.getScript('/redirect_to_root')
          }
        } else {
          addToast(message, 'error')
          mainModal.hide()
        }
      })
  })

  $('#mainModal').on('click', '#confirmTransferEvents', function(event) {
    $(this).blur()
    request.put('/transfer_events')
      .set('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
      .accept('json')
      .send({
        ids: selectedIds,
        user: $('#transfer-events-user-select').val()
      })
      .then(res => {
        addToast(res.body.success, 'notice')
        callListDatatable.ajax.reload()
        mainModal.hide()
      })
      .catch(err => {
        // err.message, err.response
        const message = err.response !== undefined && err.response.body !== undefined && err.response.body !== null ? err.response.body : null
        if (err.response.status === 401) {
          if (message !== null) {
            addToast(message, 'error')
            setTimeout(function() {
              $.getScript('/redirect_to_root')
            }, 3000)
          } else {
            $.getScript('/redirect_to_root')
          }
        } else {
          addToast(message, 'error')
          mainModal.hide()
        }
      })
  })

  $('#mainModal').on('click', '#confirmImportEvents', function(event) {
    $(this).blur()
    let files = $('#import_file')[0].files
    if (files.length > 0) {
      request.post('/import_events')
        .set('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
        .accept('json')
        .attach('file', files[0])
        .field('user', $('#import-events-user-select').val())
        .then(res => {
          addToast(res.body.success, 'notice')
          callListDatatable.ajax.reload()
          mainModal.hide()
        })
        .catch(err => {
          // err.message, err.response
          const message = err.response !== undefined && err.response.body !== undefined && err.response.body !== null ? err.response.body : null
          if (err.response.status === 401) {
            if (message !== null) {
              addToast(message, 'error')
              setTimeout(function() {
                $.getScript('/redirect_to_root')
              }, 3000)
            } else {
              $.getScript('/redirect_to_root')
            }
          } else {
            addToast(message, 'error')
            mainModal.hide()
          }
        })
    } else {
      // TODO translation
      addToast('Tiedosto puuttuu', 'alert')
    }
  })
}
