import request from 'superagent'
import moment from 'moment-timezone' // https://momentjs.com/
import { addToast } from 'src/toasts'
import { lockButton, unlockButton } from 'src/lockButton'
import { handleErrors } from 'src/errors'
import functions_contract from 'packs/customers/functions_contract'
import functions_contract_extra from 'packs/customers/functions_contract_extra'
import functions_event from 'packs/customers/functions_event'
import functions_quality_control from 'packs/customers/functions_quality_control'
import functions_service_description from 'packs/customers/functions_service_description'

function customerFunctions() {
  functions_contract()
  functions_contract_extra()
  functions_event()
  functions_quality_control()
  functions_service_description()

  // jos avattu uuteen välilehteen
  if ($('#edit_customer_id').length) {
    offcanvasRight.show()
    $.getScript('/customers/' + $('#edit_customer_id').val() + '/edit')
  }

  $("#offcanvasRight").on('show.bs.tab', function (e) {
    // TODO käännökset
    if ($(e.target.parentElement.parentElement).hasClass('customer') && !dirty) {
      $('#offcanvasRight .offcanvas-body').scrollTop(0)
      $('#offcanvasRightButtons').empty()
      if (navTarget === 'basic-information-link') {
        if (!['PO'].includes(userRole) && mainCustomer) {
          createButton('sub_inc_loc', 'primary', 'Luo alitupa', 'plus')
        }
        if (!['PO'].includes(userRole)) {
          createButton('submitForm', 'success', 'Tallenna', 'check-lg')
        }
      } else if (navTarget === 'contact-people-link') {
        createButton('submitForm', 'success', 'Tallenna', 'check-lg')
      } else if (navTarget === 'events-link') {
        // TODO create select
        //$('#event-select').removeClass('d-none')
        createButton('new', 'primary', 'Uusi', 'plus')
        createButton('submitForm', 'success', 'Tallenna', 'check-lg')
        if (eventCalendar == null || eventCalendar == undefined) {
          if (document.getElementById('eventCalendar') != null) {
            initCalendar()
          }
        } else {
          eventCalendar.refetchEvents()
        }
        $('.input-group.time input').timepicker({
          disableTextInput: true,
          maxTime: '20:45',
          minTime: '08:00',
          step: 15,
          timeFormat: 'H:i'
        })
      } else if (navTarget === 'service-descriptions-link') {
        createButton('submitForm', 'success', 'Tallenna', 'check-lg')
      } else if (navTarget === 'contracts-link') {
        // TODO käännös
        var select = $('<select/>',
        {
            id: 'contract-select',
            name: 'contract-select',
            class: 'form-select me-1'
        });
        var emptySelect = $('<option/>',
        {
          value: '',
          text: 'Valitse tarjous'
        });
        select.append(emptySelect);
        $('#offcanvasRightButtons').append(select)

        const customerId = $("#basic-information").find('form').attr('action').split('/').pop()
        request.get('/contracts/contract_options?customer_id=' + customerId)
          .set('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
          .accept('json')
          .then(res => {
            let contractId = null
            if ($("#contracts").find('form').length && $("#contracts").find('form').attr('action') !== '/contracts') {
              contractId = $("#contracts").find('form').attr('action').split('/').pop()
            }

            let options = ''
            for(var i = 0; i < res.body.length; i++) {
              options += '<option' + (parseInt(contractId) === res.body[i].id ? ' selected="selected"' : '') + ' value="' + res.body[i].id + '">' + res.body[i].status + ' ' + moment(res.body[i].updated_at).format('D.M.YYYY') + '</option>'
            }
            $('#contract-select').append(options)
          })
          .catch(err => {
            handleErrors(err, null)
          })

        if (!['PEM'].includes(userRole)) {
          if ($("#contracts").find('form').length) {
            if ($("#contracts").find('form').attr('action') !== '/contracts') {
              createButton('copyContract', 'primary', 'Kopioi', 'arrow-counterclockwise')
              if ($('#contract_status').val() === 'AVOIN') {
                createButton('createContract', 'primary', 'Sopimus', 'file-text')
              }
              createButton('new', 'primary', 'Uusi', 'plus')
              createButton('delete', 'danger', 'Poista', 'trash')
            } else {
              createButton('new', 'primary', 'Uusi', 'plus')
            }
            createButton('submitForm', 'success', 'Tallenna', 'check-lg')
          } else {
            createButton('new', 'primary', 'Uusi', 'plus')
          }
        }
      } else if (navTarget === 'contract-extra-link') {
        createButton('submitForm', 'success', 'Tallenna', 'check-lg')
      } else if (navTarget === 'quality-controls-link') {
        // TODO käännös
        var selectYear = $('<select/>',
        {
            id: 'quality-control-year-select',
            name: 'quality-control-year-select',
            class: 'form-select me-1'
        });
        var emptySelectYear = $('<option/>',
        {
          value: '',
          text: 'Valitse tilivuosi'
        });
        selectYear.append(emptySelectYear);
        $('#offcanvasRightButtons').append(selectYear)

        var selectQc = $('<select/>',
        {
            id: 'quality-control-select',
            name: 'quality-control-select',
            class: 'form-select me-1'
        });
        var emptySelectQc = $('<option/>',
        {
          value: '',
          text: 'Valitse laatukierto'
        });
        selectQc.append(emptySelectQc);
        $('#offcanvasRightButtons').append(selectQc)

        const customerId = $("#basic-information").find('form').attr('action').split('/').pop()
        let qcId = ''
        if ($("#quality-controls").find('form').length && $("#quality-controls").find('form').attr('action') !== '/quality_controls') {
          qcId = $("#quality-controls").find('form').attr('action').split('/').pop()
        }
        request.get('/quality_controls/quality_control_options?customer_id=' + customerId + '&qc_id=' + qcId)
          .set('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
          .accept('json')
          .then(res => {
            $.each(res.body.years, function(index, value) {
              var option = $('<option/>',
              {
                value: value,
                text: value
              });
              if (res.body.year == value) {
                option.attr('selected', 'selected');
              }
              selectYear.append(option);
            });

            $.each(res.body.qcs, function(index, value) {
              var option = $('<option/>',
              {
                value: value.id,
                text: (value.rating !== null ? value.rating + ' ' : '') + moment(value.check_date).format('D.M.YYYY')
              });
              if (parseInt(qcId) == value.id) {
                option.attr('selected', 'selected');
              }
              selectQc.append(option);
            });
          })
          .catch(err => {
            handleErrors(err, null)
          })

        if ($("#quality-controls").find('form').length) {
          if ($("#quality-controls").find('form').attr('action') === '/quality_controls') {
            createButton('new', 'primary', 'Uusi', 'plus')
            createButton('submitForm', 'success', 'Tallenna', 'check-lg')
          } else {
            request.get($("#quality-controls").find('form').attr('action') + '/allow_edit')
              .set('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
              .accept('json')
              .then(res => {
                if (res.body.new === true) {
                  createButton('new', 'primary', 'Uusi', 'plus')
                }
                if (res.body.delete === true) {
                  createButton('delete', 'danger', 'Poista', 'trash')
                }
                if (res.body.submit === true) {
                  createButton('submitForm', 'success', 'Tallenna', 'check-lg')
                }
              })
              .catch(err => {
                handleErrors(err, null)
              })
          }
        } else {
          createButton('new', 'primary', 'Uusi', 'plus')
        }
      } else if (navTarget === 'customer-attachments-link') {
        createButton('submitForm', 'success', 'Tallenna', 'check-lg')
      }
    }
  })

  document.getElementById('offcanvasRight').addEventListener('hidden.bs.offcanvas', function(e) {
    dirty = false
    eventCalendar = null
  })

  $('#offcanvasRight').on('click', '#create_starttilomake', function(event) {
    lockButton('#create_starttilomake')
    $('#customer_extra_data').val('create_starttilomake')
    $('#offcanvasRight .tab-pane.active form button[type=submit]').click()
  })

  $('#offcanvasRight').on('click', '#send_starttilomake', function(event) {
    lockButton('#send_starttilomake')
    $('#customer_extra_data').val('send_starttilomake')
    $('#offcanvasRight .tab-pane.active form button[type=submit]').click()
  })

  $('#offcanvasRight').on('change', '#customer_billing_type', function(event) {
    if ($(this).val() == 'POSTI') {
      $('#customer_billing_address_same').closest('.col-4').removeClass('d-none')
      $('.billing_information .row.postal').removeClass('d-none')
      $('.billing_information .row.email').addClass('d-none')
      if (userRole == 'MYYJÄ' && !$('#customer_billing_address_same').is(":checked")) {
        $('#customer_billing_country').val($('#customer_country').val()).trigger('change')
      }
    } else if ($(this).val() == 'SÄHKÖPOSTI') {
      $('#customer_billing_address_same').closest('.col-4').addClass('d-none')
      $('.billing_information .row.postal').addClass('d-none')
      $('.billing_information .row.email').removeClass('d-none')
    } else {
      $('#customer_billing_address_same').closest('.col-4').addClass('d-none')
      $('.billing_information .row.postal').addClass('d-none')
      $('.billing_information .row.email').addClass('d-none')
    }
  })

  $('#offcanvasRight').on('change', '#customer_billing_address_same', function(event) {
    if ($('#customer_billing_type').val() == 'POSTI') {
      $('#customer_billing_street_name').prop('disabled', $(this).is(":checked"))
      $('#customer_billing_post_code').prop('disabled', $(this).is(":checked"))
      $('#customer_billing_town').prop('disabled', $(this).is(":checked"))
      if ($(this).is(":checked")) {
        $('#customer_billing_street_name').val('')
        $('#customer_billing_post_code').val('')
        $('#customer_billing_town').val('')
        $('#customer_billing_country').val('').trigger('change')
      } else {
        $('#customer_billing_country').val($('#customer_country').val()).trigger('change')
      }
    }
  })

  $('#offcanvasRight').on('change', '.select_contact_person', function(event) {
    const id = $(this).val()
    const tab = $("#offcanvasRight .tab-pane.active").attr('id')
    if (tab == 'contracts') {
      $('#contract_contact_person_id').val(id)
    }
    
    if (id) {
      lockButton(event.target)

      const customerId = $("#basic-information").find('form').attr('action').split('/').pop()
      request.get(/customers/ + customerId + /contact_people/ + id)
        .set('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
        .accept('json')
        .then(res => {
          $(this).closest('.contact_person').find('input[name*="first_name"]').val(res.body.first_name)
          $(this).closest('.contact_person').find('input[name*="first_name"]').prop("disabled", res.body.deleted)
          $(this).closest('.contact_person').find('input[name*="last_name"]').val(res.body.last_name)
          $(this).closest('.contact_person').find('input[name*="last_name"]').prop("disabled", res.body.deleted)
          $(this).closest('.contact_person').find('input[name*="phone"]').val(res.body.phone)
          $(this).closest('.contact_person').find('input[name*="phone"]').prop("disabled", res.body.deleted)
          $(this).closest('.contact_person').find('input[name*="email"]').val(res.body.email)
          $(this).closest('.contact_person').find('input[name*="email"]').prop("disabled", res.body.deleted)
          $(this).closest('.contact_person').find('input[name*="title"]').val(res.body.title)
          $(this).closest('.contact_person').find('input[name*="title"]').prop("disabled", res.body.deleted)

          unlockButton(event.target)
        })
        .catch(err => {
          handleErrors(err, event.target)
        })
    } else {
      $(this).closest('.contact_person').find('input[name*="first_name"]').val('')
      $(this).closest('.contact_person').find('input[name*="first_name"]').prop("disabled", false)
      $(this).closest('.contact_person').find('input[name*="last_name"]').val('')
      $(this).closest('.contact_person').find('input[name*="last_name"]').prop("disabled", false)
      $(this).closest('.contact_person').find('input[name*="phone"]').val('')
      $(this).closest('.contact_person').find('input[name*="phone"]').prop("disabled", false)
      $(this).closest('.contact_person').find('input[name*="email"]').val('')
      $(this).closest('.contact_person').find('input[name*="email"]').prop("disabled", false)
      $(this).closest('.contact_person').find('input[name*="title"]').val('')
      $(this).closest('.contact_person').find('input[name*="title"]').prop("disabled", false)
    }
  })

  $('#offcanvasRight').on('click', '#copy_basic', function(event) {
    $(this).blur()
    const street_name = $("#customer_street_name").val()
    $('#event_street_name').val(street_name)
    const post_code = $("#customer_post_code").val()
    $('#event_post_code').val(post_code)
    const town = $("#customer_town").val()
    $('#event_town').val(town)
    const country = $("#customer_country").val()
    $('#event_country').val(country)
  })

  $('#offcanvasRight').on('click', '#new, #sub_inc_loc', function(event) {
    if ($("#basic-information").find('form').attr('action').includes('customers')) {
      if (!dirty) {
        let url = $("#offcanvasRight .tab-pane.active").attr('id').replace('-', '_')
        if (url == 'basic_information') {
          lockButton('#sub_inc_loc')
          url = 'customers'
        } else {
          lockButton('#new')
        }
        const customerId = $("#basic-information").find('form').attr('action').split('/').pop()
        $.getScript('/' + url + '/new?customer_id=' + customerId)

        // TODO onko tarpeeton, voiko laittaa new.js jos tarvitaan?
        /*if ($("#offcanvasRight .tab-pane.active").attr('id') == 'events') {
          eventCalendar = null
        }*/
      } else {
        confirmDirtyNav()
      }
    }
  })

  $('#offcanvasRight').on('click', '#submitForm', function(event) {
    if ($("#basic-information").find('form').attr('action').includes('customers')) {
      lockButton('#submitForm')
      $('#offcanvasRight .tab-pane.active form #contract_extra_data').val('')
      $('#offcanvasRight .tab-pane.active form button[type=submit]').click()

      // TODO onko tarpeeton, voiko laittaa edit.js jos tarvitaan?
      /*if ($("#offcanvasRight .tab-pane.active").attr('id') == 'events') {
        eventCalendar = null
      }*/
    }
  })

  $('#mainModal').on('click', '#confirmDelete', function(event) {
    $(this).blur()
    if ($("#basic-information").find('form').attr('action').includes('customers')) {
      lockButton('#confirmDelete')

      request.delete($("#offcanvasRight .tab-pane.active form").attr('action'))
        .set('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
        .accept('json')
        .then(res => {
          if ($('#offcanvasRight .tab-pane.active').attr('id') === 'contracts') {
            const offerId = $("#contracts").find('form').attr('action').split('/').pop()
            $("#contract-select option[value='" + offerId + "']").remove()

            if ($('#contract-select option:contains(HYVÄKSYTTY)').length == 0) {
              $('#quality-controls-link').addClass('d-none')
            }

            $('#copyContract').addClass('d-none')
            $('#createContract').addClass('d-none')
          } else if ($('#offcanvasRight .tab-pane.active').attr('id') === 'quality-controls') {
            const controlId = $("#quality-controls").find('form').attr('action').split('/').pop()
            $("#quality-control-select option[value='" + controlId + "']").remove()

            if ($("#quality-control-select option").length === 1) {
              const year = $("#quality-control-year-select").val()
              $("#quality-control-year-select option[value='" + year + "']").remove()
            }
            $('#quality-control-year-select').val('').trigger('change')
          }

          $("#offcanvasRight .tab-pane.active").html('')
          $('#delete').addClass('d-none')
          $('#submitForm').addClass('d-none')

          addToast(res.body.success, 'notice')
          mainModal.hide()
          dirty = false
        })
        .catch(err => {
          handleErrors(err, '#confirmDelete')
        })
    }
  })

  $('#offcanvasRight').on('click', '.add_fields.add_contact_person, .add_fields.add_event, .add_fields.add_contract_person, .add_fields.add_customer_attachment', function(event) {
    dirty = true

    var regexp, time
    time = new Date().getTime()
    regexp = new RegExp($(this).data('id'), 'g')
    if ($(this).hasClass('add_contact_person')) {
      $(this).closest('.tab-pane').find('.contact_person_rows').append($(this).data('fields').replace(regexp, time))
    } else if ($(this).hasClass('add_event')) {
      $(this).closest('.tab-pane').find('.event_rows').append($(this).data('fields').replace(regexp, time))
    } else if ($(this).hasClass('add_contract_person')) {
      $(this).closest('.tab-pane').find('.contract_person_rows').prepend($(this).data('fields').replace(regexp, time))

      // TODO teksti käännös
      $('.tab-pane.active .contract_person:first .select_contract_person').select2({
        ajax: {
          url: 'contract_people_options',
          dataType: 'json'
        },
        allowClear: true,
        dropdownParent: $('#offcanvasRight'),
        placeholder: 'Uusi',
        width: '100%'
      })
    } else if ($(this).hasClass('add_customer_attachment')) {
      $(this).closest('.tab-pane').find('.customer_attachment_rows').append($(this).data('fields').replace(regexp, time))
    }

    $(this).blur()

    return event.preventDefault()
  })

  $('#offcanvasRight').on('click', '.remove-record', function(event) {
    dirty = true

    $(this).prev('input[type=hidden]').val('1')
    $(this).closest('.row').nextAll('hr:first').hide()
    $(this).closest('.row').hide()

    return event.preventDefault()
  })

  $('#offcanvasRight').on('click', '.remove-file, .remove-area-file', function(event) {
    dirty = true

    let input = null
    if ($(this).hasClass('remove-file')) {
      input = $(this).closest('.col-4').find('input[type=hidden]')
    } else {
      input = $(this).closest('.d-block').find('input[type=hidden]')
    }
    let val = input.val()
    if (val.length) {
      val += ','
    }
    val += $(this).attr('data-id')
    input.val(val)

    $(this).closest('.row').hide()

    return event.preventDefault()
  })

  $('#mainModal').on('click', '#submitSendStarttilomake', function(event) {
    $(this).blur()
    const recipients = $('#customer_starttilomake_recipients').val()

    lockButton('#submitSendStarttilomake')

    const customerId = $("#basic-information").find('form').attr('action').split('/').pop()
    request.post(/customers/ + customerId + /send_starttilomake/)
      .set('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
      .accept('json')
      .send({
        recipients: recipients
      })
      .then(res => {
        addToast(res.body.success, 'notice')
        unlockButton('#submitSendStarttilomake')
        mainModal.hide()
      })
      .catch(err => {
        if (err.response.status === 422 && !$('#customer_starttilomake_recipients').hasClass('is-invalid')) {
          $('#customer_starttilomake_recipients').addClass('is-invalid')
          $('#mainModal .form-group.form-floating span.form-select').addClass('is-invalid')
          let message = err.response.body.errors
          message = message.substr(message.indexOf(' ') + 1)
          message = message.charAt(0).toUpperCase() + message.slice(1)
          $('<div class="errors">' + message + '</div>').appendTo('#mainModal .form-group.form-floating')
        }

        handleErrors(err, '#submitSendStarttilomake')
      })
  })
}

function createButton(id, color, text, icon) {
  var button = $('<button/>',
  {
      id: id,
      class: 'btn btn-' + color + ' me-1',
      html: (icon.length > 0 ? '<i class="bi bi-' + icon + '"></i> ' : '') + text
  });
  $('#offcanvasRightButtons').append(button)
}

export { customerFunctions }
