import { lockButton } from 'src/lockButton'

function contractPeopleFunctions(contractPeopleDatatable) {
  $('#offcanvasRight').on('click', '#submitForm', function(event) {
    if ($("#basic-information").find('form').attr('action').includes('contract_people')) {
      lockButton('#submitForm')
      $('#offcanvasRight .tab-pane.active form button[type=submit]').click()
    }
  })

  $('#offcanvasRight').on('click', '.add_fields.add_contract_extra', function(event) {
    dirty = true

    var regexp, time
    time = new Date().getTime()
    regexp = new RegExp($(this).data('id'), 'g')
    $(this).closest('.tab-pane').find('.contract_extra_rows').prepend($(this).data('fields').replace(regexp, time))

    // TODO teksti käännös
    $('.tab-pane.active .contract_extra_row:first .customer-select').select2({
      ajax: {
        url: 'service_description_options/customer_options?all=true',
        dataType: 'json'
      },
      dropdownParent: $('#offcanvasRight'),
      placeholder: 'Valitse asiakas',
      width: '100%'
    })

    $(this).blur()

    return event.preventDefault()
  })

  $('#offcanvasRight').on('click', '.remove-record', function(event) {
    dirty = true

    $(this).prev('input[type=hidden]').val('1')
    $(this).closest('.row').nextAll('hr:first').hide()
    $(this).closest('.row').hide()

    return event.preventDefault()
  })
}

export { contractPeopleFunctions }
