import { addToast } from 'src/toasts'
import { unlockButton } from 'src/lockButton'

export function handleErrors(error, button) {
  if (error && error.response) {
    const message = error.response !== undefined && error.response.body !== undefined && error.response.body !== null ? error.response.body : null
    if (error.response.status === 401) {
      if (message !== null) {
        addToast(message, 'error')
        setTimeout(function() {
          $.getScript('/redirect_to_root')
        }, 3000)
      } else {
        $.getScript('/redirect_to_root')
      }
    } else {
      addToast(message, 'error')
      if (button !== null) {
        unlockButton(button)
      }
    }
  } else {
    // TOOD teksti
    addToast('Odottamaton virhe', 'error')
    if (button !== null) {
      unlockButton(button)
    }
  }
}