import request from 'superagent'
import { customerFunctions } from 'packs/customers/functions'
import { lockButton, unlockButton } from 'src/lockButton'

export default () => {
  customerFunctions()
  
	// datatable init
  let customersDatatable = $('#customers-datatable').DataTable({
    "language": {
      url: "/datatable_i18n"
    },
    "pageLength": 25,
    "processing": true,
    "responsive": true,
    "serverSide": true,
    "ajax": {
      "url": $('#customers-datatable').data('source'),
      "data": function (d) {
        d.ail = $('#select-ail').val()
        d.status = $('#select-status').val()
      }
    },
    "columns": [
      {"data": "company_name"},
      {"data": "location_name"},
      {"data": "business_id"},
      {"data": "income_location"},
      {"data": "aux_inc_location"},
      {"data": "address"},
      {"data": "phone"},
      {"data": "email"},
      {"data": "status"}
    ],
    initComplete(settings, json) {
      $("#datatable-actions").prependTo('#customers-datatable_wrapper')
      $("#customers-datatable_length").prependTo('#datatable-actions')
      $("#customers-datatable_length").addClass('me-3')
      $("#customers-datatable_filter input").removeClass('form-control-sm')
      $("#customers-datatable_filter input").addClass('datatable-search')
      $('#customers-datatable_filter input').attr("type", 'text')
      $("#customers-datatable_filter input").detach().prependTo('#search-div')
      $('#customers-datatable_wrapper .row:first').remove()
      $("#datatable-actions").removeClass('d-none')

      $('.datatable-search').on('input', function() {
        if ($('#search-div input').val()) {
          $('.clear-button').removeClass('d-none')
        } else {
          $('.clear-button').addClass('d-none')
        }
      })
  	}
  })

  $('#content').on('change', '#select-ail, #select-status', function() {
    customersDatatable.ajax.reload()
  })

  $('.clear-button').on('click', function() {
    $('.clear-button').addClass('d-none')
    customersDatatable.search('').draw()
  })

  $('#content').on('click', '#add_customer', function() {
    lockButton('#add_customer')
    offcanvasRight.show()
    $.getScript('/customers/new')
  })

  $('#content').on('click', '#customers, #contact_people', function(event) {
    lockButton(event.target)
    let status = $('#select-status').val()
    let query = $('#search-div input').val()
    let url = $(event.target).attr('id')
    let q = '/customers/' + url + '_list?status=' + status + '&query=' + query
    
    if ($('#select-ail').length) {
      q += '&ail=' + $('#select-ail').val()
    }

    let dynamicLink = $('<a>', {
      href: q,
      target: '_blank',
      css: { display: 'none' }
    })

    $('body').append(dynamicLink)
    dynamicLink[0].click()
    dynamicLink.remove()

    unlockButton(event.target)
  })
}
