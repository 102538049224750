import request from 'superagent'
import { lockButton, unlockButton } from 'src/lockButton'
import { handleErrors } from 'src/errors'

export default () => {
  $('#offcanvasRight').on('change', '.select_contract_person', function(event) {
    const id = $(this).val()
    if (id) {
      $(this).closest('.contract_extra_contract_person').find('input[name*="contract_person_id"]').val(id)
      lockButton(event.target)

      request.get(/contract_people/ + id)
        .set('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
        .accept('json')
        .then(res => {
          $(this).closest('.contract_person').find('input[name*="first_name"]').val(res.body.first_name)
          $(this).closest('.contract_person').find('input[name*="last_name"]').val(res.body.last_name)
          $(this).closest('.contract_person').find('input[name*="phone"]').val(res.body.phone)
          $(this).closest('.contract_person').find('select[name*="role"]').val('')
          $(this).closest('.contract_person').find('textarea[name*="notes"]').val('')
        
          unlockButton(event.target)
        })
        .catch(err => {
          handleErrors(err, event.target)
        })
    } else {
      $(this).closest('.contract_person').find('input[name*="first_name"]').val('')
      $(this).closest('.contract_person').find('input[name*="last_name"]').val('')
      $(this).closest('.contract_person').find('input[name*="phone"]').val('')
      $(this).closest('.contract_person').find('select[name*="role"]').val('')
      $(this).closest('.contract_person').find('textarea[name*="notes"]').val('')
    }
  })
}