import { Toast } from 'bootstrap'

export function showToasts() {
  document.querySelectorAll('.toast.new').forEach((toastTarget) => {
    toastTarget.addEventListener('shown.bs.toast', function () {
      $(this).removeClass('new')
    })
    toastTarget.addEventListener('hidden.bs.toast', function () {
      this.remove()
    })
    return new Toast(toastTarget).show()
  })
}

export function addToast(text, type, sign_in = 'normal') {
  if (text == null) {
    // TODO parempi teksti
    toastText('Virhe', 'error', sign_in)
  } else if (Array.isArray(text)) {
    text.forEach(function myFunction(item, index, arr) {
      toastText(item, type, sign_in)
    })
  } else if (typeof text == 'object') {
    Object.keys(text).forEach(function(key) {
      if (typeof text[key] === 'string' || text[key] instanceof String) {
        toastText(text[key], type, sign_in)
      } else {
        addToast(text[key], type, sign_in)
      }
    })
  } else {
    toastText(text, type, sign_in)
  }
}

function toastText(text, type, sign_in) {
  let textType = 'bg-success text-white'
  let iconType = 'check'
  let btnType = 'btn-close-white'
  if (type == 'error') {
    textType = 'bg-danger text-white'
    iconType = 'x'
    btnType = 'btn-close-white'
  } else if (type == 'alert') {
    textType = 'bg-warning text-white'
    iconType = 'exclamation'
    btnType = 'btn-close-white'
  }

  if (sign_in == 'normal') {
    $('.toast-container').prepend("<div class='toast fade " + textType + " new border-0 h4 w-100' role='alert' aria-live='assertive' aria-atomic='true'><div class='d-flex'><div class='toast-body'><table><tbody><tr><td class='p-0 pe-2'><i class='bi bi-" + iconType + "-circle'></i></td><td class='p-0'>" + text + "</td><td class='p-0 ps-2'><button type='button' class='btn-close " + btnType + "' data-bs-dismiss='toast' aria-label='Close'></button></td></tr></tbody></table></div></div></div>")
  } else {
    $('.toast-container').prepend("<div class='toast fade new border-0 w-100' role='alert' aria-live='assertive' aria-atomic='true'> <div class='toast-header " + textType + "'><i class='bi bi-" + iconType + "-circle me-2'></i> <strong class='me-auto'>" + text + "</strong></div></div>")
  }
}
