import { customerFunctions } from 'packs/customers/functions'
import { addToast } from 'src/toasts'
import { handleErrors } from 'src/errors'
import { Calendar } from '@fullcalendar/core' // https://fullcalendar.io/
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import fiLocale from '@fullcalendar/core/locales/fi'
import request from 'superagent'
import moment from 'moment' // https://momentjs.com/
import { Tooltip } from 'bootstrap'

export default () => {
  customerFunctions()

  let tooltipInstance = null
  const calendarEl = document.getElementById('calendar')
  let calendar = new Calendar(calendarEl, {
    plugins: [ timeGridPlugin, interactionPlugin ],
    aspectRatio: 2.25,
    editable: false,
    eventClick: function(info) {
      const customerId = info.event.extendedProps.customerId

      if (customerId) {
        if (tooltipInstance) {
          tooltipInstance.dispose()
          tooltipInstance = null
        }

        const url = '/customers/' + customerId + '/edit?event_id=' + info.event.id

        offcanvasRight.show()

        request.get(url)
          .accept('text/javascript')
          .set('X-Requested-With', 'XMLHttpRequest')
          .then(res => {
            eval(res.text)
          })
          .catch(err => {
            handleErrors(err, null)
            offcanvasRight.hide()
          })
      }
    },
    eventMouseEnter: function(mouseEnterInfo) {
      if (mouseEnterInfo.event.extendedProps.notes) {
        tooltipInstance = new Tooltip(mouseEnterInfo.el, {
          title: mouseEnterInfo.event.extendedProps.notes.replace(new RegExp('\n', 'g'), '<br>'),
          placement: 'bottom',
          trigger: 'hover',
          container: 'body',
          html: true
        })

        tooltipInstance.show()
      }
    },
    eventMouseLeave: function(mouseLeaveInfo) {
      if (tooltipInstance) {
        tooltipInstance.dispose()
        tooltipInstance = null
      }
    },
    eventSources: [
      function(info, successCallback, failureCallback) {
        request.get('/events')
          .accept('json')
          .query({
            start: info.startStr,
            end: info.endStr,
            user_id: $('#userId').val()
          })
          .then(res => {
            successCallback(res.body)
          })
          .catch(err => {
            handleErrors(err, null)
            failureCallback(err)
          })
      },
      function(info, successCallback, failureCallback) {
        request.get('/ms_events')
          .accept('json')
          .query({
            start: info.startStr,
            end: info.endStr,
            user_id: $('#userId').val()
          })
          .then(res => {
            successCallback(res.body)

          })
          .catch(err => {
            handleErrors(err, null)
            failureCallback(err)
          })
      }
    ],
    firstDay: 1,
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: ''
    },
    initialView: 'timeGridWeek',
    locale: fiLocale,
    nowIndicator: true,
    snapDuration: '00:15:00',
    slotMaxTime: "21:00:00",
    slotMinTime: "08:00:00",
    weekends: false
  })
  calendar.render()
}