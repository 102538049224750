import request from 'superagent'
import { addToast } from 'src/toasts'
import { lockButton, unlockButton } from 'src/lockButton'
import { handleErrors } from 'src/errors'
import Chartkick from "chartkick"
import "chartkick/chart.js"

export default () => {
  $('.date input').datepicker()

  $('.dashboard-po').on('click', '#refresh', function () {
    lockButton('#refresh')
    
    const dateStart = $('#date_start').val()
    const dateEnd = $('#date_end').val()

    request.get('/event_stats?date_start=' + dateStart + '&date_end=' + dateEnd)
      .set('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
      .accept('json')
      .then(res => {
        let chart = Chartkick.charts["chart-1"]
        chart.updateData(res.body)

        unlockButton('#refresh')
      })
      .catch(err => {
        handleErrors(err, '#refresh')
      })
  })
}
