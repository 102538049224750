import request from 'superagent'
import { lockButton, unlockButton } from 'src/lockButton'
import { handleErrors } from 'src/errors'

export default () => {
  $('#offcanvasRight').on('change', '#contract-select', function(event) {
    if (!dirty) {
      const id = $('#contract-select').val()
      if (id) {
        lockButton('#contract-select')
        $.getScript(/contracts/ + id + '/edit')
      }
    } else {
      confirmDirtyNav()
    }
  })

  $('#offcanvasRight').on('click', '#copyContract', function(event) {
    if (!dirty) {
      lockButton('#copyContract')
      const contractId = $("#contracts").find('form').attr('action').split('/').pop()
      $.getScript('/contracts/new?contract_id=' + contractId)
    } else {
      confirmDirtyNav()
    }
  })

  $('#offcanvasRight').on('click', '#createContract', function(event) {
    lockButton('#createContract')
    $('#contract_extra_data').val('open_modal')
    $('#offcanvasRight .tab-pane.active form button[type=submit]').click()
  })

  $('#offcanvasRight').on('change', '#contract_status', function(event) {
    if (!['PEM'].includes(userRole)) {
      if ($(this).val() == 'AVOIN') {
        $('#createContract').removeClass('d-none')
        $('#offcanvasRight .contract').closest('div').addClass('d-none')
      } else if ($(this).val() == 'HYLÄTTY') {
        $('#createContract').addClass('d-none')
        $('#offcanvasRight .contract').closest('div').addClass('d-none')
      } else {
        $('#createContract').addClass('d-none')
        $('#offcanvasRight .contract').closest('div').removeClass('d-none')
      }
    }
  })

  $('#mainModal').on('change', '#contract_period', function(event) {
    if (!['PEM'].includes(userRole)) {
      if ($(this).val() != 'Muu') {
        $('#mainModal #contract_period_text').closest('div').addClass('d-none')
      } else {
        $('#mainModal #contract_period_text').closest('div').removeClass('d-none')
      }
    }
  })

  $('#offcanvasRight').on('change', '#contract_payment_terms_days', function(event) {
    if (!['PEM'].includes(userRole)) {
      if ($(this).val() != 'Muu') {
        $('#offcanvasRight #contracts #contract_payment_terms_days_text').prop('disabled', true)
      } else {
        $('#offcanvasRight #contracts #contract_payment_terms_days_text').prop('disabled', false)
      }
    }
  })

  $('#offcanvasRight').on('change', '#contract_work_weekdays', function(event) {
    if (!['PEM'].includes(userRole)) {
      if ($(this).val() != 'Muu') {
        $('#offcanvasRight #contracts #contract_work_weekdays_notes').prop('disabled', true)
      } else {
        $('#offcanvasRight #contracts #contract_work_weekdays_notes').prop('disabled', false)
      }
    }
  })

  $('#offcanvasRight').on('change', '#contract_contract_period', function(event) {
    if (!['PEM'].includes(userRole)) {
      if ($(this).val() != 'Muu') {
        $('#offcanvasRight #contracts #contract_contract_period_text').prop('disabled', true)
      } else {
        $('#offcanvasRight #contracts #contract_contract_period_text').prop('disabled', false)
      }
    }
  })

  $('#offcanvasRight').on('click', '#create_contract_offer', function(event) {
    lockButton('#create_contract_offer')
    $('#contract_extra_data').val('create_offer')
    $('#offcanvasRight .tab-pane.active form button[type=submit]').click()
  })

  $('#offcanvasRight').on('click', '#create_contract', function(event) {
    lockButton('#create_contract')
    $('#contract_extra_data').val('create_contract')
    $('#offcanvasRight .tab-pane.active form button[type=submit]').click()
  })

  $('#mainModal').on('click', '#submitContract', function(event) {
    lockButton('#submitContract')
    $('#mainModal form button[type=submit]').click()
  })

  $('#mainModal').on('change', '#contract_aux_inc_location', function(event) {
    lockButton('#contract_aux_inc_location')

    const contractId = $("#contracts").find('form').attr('action').split('/').pop()
    request.get('/contracts/starttilomake_recipients?ail=' + $(this).val() + '&contract=' + contractId)
      .set('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
      .accept('json')
      .then(res => {
        $("#contract_starttilomake_recipients").val(res.body).trigger('change')
      
        unlockButton('#contract_aux_inc_location')
      })
      .catch(err => {
        handleErrors(err, '#contract_aux_inc_location')
      })
  })
}